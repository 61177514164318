import React, { useContext } from 'react';
import { WiEarthquake } from 'react-icons/wi';
import dayjs from 'dayjs';

// CONTEXT
import { FaCalendarCheck } from 'react-icons/fa';
import { formatDate, formatTime } from '../../Panel/utils/format';

const QuakeItem = ({ quake, isSelected, onSelect }) => {
    const handleShowInfo = () => {
        onSelect(quake);
    };

    const currentTime = dayjs(new Date());
    const _quakeDate = dayjs(quake.date);

    let colorQuake = '';
    if (currentTime.diff(_quakeDate, 'hours') <= 24) colorQuake = 'bg-red-500';
    if (
        currentTime.diff(_quakeDate, 'hours') >= 25 &&
        currentTime.diff(_quakeDate, 'hours') <= 48
    )
        colorQuake = 'bg-amber-500';
    if (currentTime.diff(_quakeDate, 'hours') >= 49)
        colorQuake = 'bg-yellow-500';

    return (
        <>
            <div
                id={`quakeItem_${quake.id}`}
                onClick={handleShowInfo}
                className={`quakeItem w-full ${
                    isSelected && 'bg-gray-300 odd:bg-gray-300'
                } flex cursor-pointer items-center justify-between bg-white py-1 text-xs odd:bg-slate-100 hover:bg-slate-200`}
            >
                <div className="quakeDetails flex w-full items-center justify-between px-3">
                    <div
                        className="magnitudQuake flex w-3/12 items-center justify-start"
                        title={
                            'Magnitud: ' + quake.magnitude + ' ' + quake.unit
                        }
                    >
                        <div className="quakeIcon mr-2">
                            <WiEarthquake className="bg-yellow-500 text-xl text-white" />
                        </div>
                        <div className="quake.magnitude mr-3 flex items-center text-lg font-bold text-brand-brown">
                            {quake.magnitude.toFixed(1)}{' '}
                            <sub className="ml-1 mr-2 text-xs">
                                {quake.unit}
                            </sub>
                        </div>
                    </div>
                    <div
                        className="quake.depth flex w-3/12 items-center justify-start border-r border-gray-100"
                        title={'Profundidad: ' + quake.depth + 'Kms'}
                    >
                        {quake.depth} Kms
                    </div>
                    <div
                        className="quake.date item-center flex flex w-4/12 items-center justify-start"
                        title={
                            'Fecha: ' +
                            formatDate(quake.datetime) +
                            ' ' +
                            formatTime(quake.datetime)
                        }
                    >
                        <FaCalendarCheck className="mr-2 text-base text-gray-500" />
                        <span className="flex flex-col">
                            <span className="text-xs uppercase">
                                {formatTime(quake.datetime)}
                            </span>
                            <span className="text-xs font-bold">
                                {formatDate(quake.datetime)}
                            </span>
                        </span>
                    </div>
                </div>
            </div>
        </>
    );
};

export default QuakeItem;
