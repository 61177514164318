import React, { useState } from 'react';
import { FaChevronRight } from "react-icons/fa";

// COMPONENTS
import QuakeItem from './QuakeItem'

const QuakesSidebar = (props) => {
    const {onSidebarToggle, sidebarOpen, stylesSidebar, selectedItem, onItemSelected} = props;

    const showSidebar = () => onSidebarToggle(!sidebarOpen);

    const quakes = props.quakesData;

    return (
        <>
            <div className={stylesSidebar}>
                <a
                    className="handlerSidebar w-14 h-14 flex justify-center items-center cursor-pointer bg-brand-brown text-white text-8 absolute -right-14 top-0"
                    onClick={showSidebar}
                >
                    <FaChevronRight className={`${sidebarOpen ? "rotate-180" : "rotate-0"}`} />
                </a>
                <div className="sidebarHeader w-full flex items-center pl-7 bg-brand-blue text-xl py-2 text-white">
                    Sismos recientes
                </div>

                <div className="quakeList flex flex-wrap justify-center overflow-y-scroll" style={{ height: 'calc(100% - 60px)' }}>
                    <div className="headerTable w-full text-left justify-between items-center px-3 py-2 flex text-xs uppercase text-gray-500">
                        <div className="headerTitle w-3/12" title="Magnitud">Magnitud</div>
                        <div className="headerTitle w-3/12 truncate" title="Profundidad">Profundidad</div>
                        <div className="headerTitle w-4/12" title="Fecha">Fecha<sup>(Local)</sup></div>
                    </div>
                    {
                        quakes.map(quake => (
                            <QuakeItem
                                key={quake.id}
                                quake={quake}
                                onSelect={onItemSelected}
                                isSelected={quake.id === selectedItem?.id}
                            />
                        ))
                    }
                </div>
            </div>
        </>
    )
}

export default QuakesSidebar;
